import React from "react";
import PropTypes from "prop-types";

import { toast } from "react-smart-toaster";
import {
  Button,
  FormGroup,
  InputGroup,
  MenuItem,
  Switch,
  TextArea
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { DateInput } from "@blueprintjs/datetime";

import { api } from "../feathers";

// =========================================

function BooleanField(props) {
  const [checked, setChecked] = React.useState(props.value);
  function onChange({ target }) {
    const value = target.checked;
    setChecked(value);
    props.onChange({ target: { value } });
  }
  return <Switch checked={checked} onChange={onChange} />;
}

// =========================================

export function SelectField({ value, onChange, translate, ...props }) {
  // options is a list of strings
  const [selection, setSelection] = React.useState(value);
  // console.log("Select: ", value, props.items);

  function itemRenderer(item, renderProps) {
    return (
      <MenuItem
        active={renderProps.modifiers.active}
        key={renderProps.index}
        onClick={renderProps.handleClick}
        text={props.itemRenderer(item)}
      />
    );
  }

  return (
    <Select
      {...props}
      filterable={props.itemPredicate !== undefined}
      activeItem={selection}
      itemRenderer={itemRenderer}
      onItemSelect={val => {
        onChange({ target: { value: val } }); // fake event
        setSelection(val);
      }}
    >
      <Button
        text={selection ? props.itemRenderer(selection) : translate("CHOOSE")}
        rightIcon="caret-down"
      />
    </Select>
  );
}

SelectField.propTypes = {
  translate: PropTypes.func.isRequired
};

SelectField.defaultProps = {
  itemRenderer: item => item
};

// =========================================

export function DateField(props) {
  const today = new Date();

  return (
    <DateInput
      minDate={new Date(2020, 0, 1)}
      maxDate={new Date(today.getFullYear() + 2, 11, 31)}
      formatDate={date =>
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
      }
      parseDate={str => new Date(str)}
      placeholder={"dd-mm-yyyy"}
      {...props}
      showActionsBar={true}
      onChange={(value, isUserChange) => {
        if (isUserChange) props.onChange({ target: { value } });
      }}
    />
  );
}

// =========================================

function Field({ label, onChange, children, ...props }) {
  const Widget = {
    boolean: BooleanField,
    date: DateField,
    hidden: InputGroup,
    password: InputGroup,
    select: SelectField,
    text: InputGroup,
    textarea: TextArea
  }[props.type];

  return (
    <FormGroup label={props.type === "hidden" || label}>
      <Widget onChange={({ target }) => onChange(target.value)} {...props} />
    </FormGroup>
  );
}

Field.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any
};

Field.defaultProps = {
  type: "text",
  required: false
};

// =========================================

export function ControlledForm({
  buttons,
  fields,
  name,
  put,
  readOnly,
  title,
  validate,
  ...props
}) {
  name = name || title;
  fields = fields.map(field => ({
    ...field,
    name: field.name || field.label.toLowerCase().replace(" ", "-")
  }));
  const [values, setValues] = React.useState(
    fields.reduce((obj, field) => ({ ...obj, [field.name]: field.value }), {})
  );

  function onChange(name, value) {
    setValues({ ...values, [name]: value });
  }

  function onSubmit(event) {
    const errors = validate(values);
    if (errors) {
      errors.forEach(text => toast.error(text));
    } else {
      put(values);
    }
    event.preventDefault();
  }

  return (
    <div>
      <h3>{title}</h3>
      <form id={props.name} {...props}>
        {fields.map((field, nr) => (
          <Field
            key={nr}
            {...field}
            value={values[field.name]}
            onChange={value => onChange(field.name, value)}
          />
        ))}
        {readOnly || (
          <Button
            key="submit"
            className="right bottom"
            type="submit"
            onClick={onSubmit}
          >
            {props.submit}
          </Button>
        )}
        {buttons.map(({ text, ...button }, nr) => (
          <Button
            key={nr}
            className="right bottom"
            {...button}
            onClick={() => button.onClick(values)}
          >
            {text}
          </Button>
        ))}
      </form>
    </div>
  );
}

ControlledForm.propTypes = {
  buttons: PropTypes.array,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  put: PropTypes.func,
  submit: PropTypes.string,
  title: PropTypes.string,
  validate: PropTypes.func
};

ControlledForm.defaultProps = {
  buttons: [],
  put: () => undefined,
  validate: () => undefined,
  submit: "Opslaan"
};

// =========================================

export function CollectionForm({ collection, put, id, ...props }) {
  function putCollection(values) {
    api.save(collection, values, id).then(result => put(result));
  }
  return <ControlledForm put={putCollection} {...props} />;
}

CollectionForm.propTypes = {
  collection: PropTypes.string.isRequired,
  id: PropTypes.string,
  put: PropTypes.func
};

CollectionForm.defaultProps = {
  put: () => undefined
};

// =========================================
