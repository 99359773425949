import React from "react";
import PropTypes from "prop-types";
import { Link, useParams, useHistory } from "react-router-dom";

import { toast } from "react-smart-toaster";
import { Spinner } from "@blueprintjs/core";

import { api } from "../feathers";
import { Survey as SurveyComponent } from "../components/Survey";
import { SurveyPrint } from "../components/Survey/Print";

//import demo from "./demo.json";

// =========================================

export function Survey({ user, format, translate }) {
  const [survey, setSurvey] = React.useState();
  const { surveyId, teamId } = useParams();
  const history = useHistory();
  React.useEffect(() => {
    Promise.all([
      api.get("teams", teamId),
      api.get("surveys", surveyId),
      api.load("answers", {
        surveyId,
        teamId,
        userId: user._id,
        $sort: { finished: -1, updatedAt: -1 }
      })
    ]).then(response => {
      const [teamResponse, surveyResponse, answersResponse] = response;

      if (!(answersResponse && surveyResponse && answersResponse)) {
        window.alert("Failed to load data in time. Please retry.");
        history.goBack();
        return;
      }

      surveyResponse.answers = { pages: [] };
      if (
        teamResponse &&
        teamResponse.memberIds.includes(user._id) &&
        teamResponse.surveyIds.includes(surveyId)
      ) {
        if (answersResponse.total > 0) {
          surveyResponse.answers = answersResponse.data[0];
          if (answersResponse.total > 1) {
            console.warn(
              `Too many answers (${answersResponse.total} should be one)`
            );
            console.log(answersResponse.data);
          }
        }

        setSurvey(surveyResponse);
      } else {
        toast.error("You do not have access to this survey");
      }
    });
  }, [surveyId, teamId, user._id, history]);

  function setAnswer(pageName, questionName, value, meta) {
    // sync UI with answers, without updating the state or database
    const { answers } = survey;

    // sanity check
    if (!(pageName && questionName)) {
      return;
    }

    // remove answer if value is empty
    if (value === undefined) {
      const page = answers.pages.indexOf(page => page.page === pageName);
      if (page >= 0) {
        const question = page.questions.indexOf(
          question => question.question === questionName
        );
        if (question >= 0) {
          if (page.questions.length === 1) {
            // remove page
            answers.pages.slice(page, page + 1);
          } else {
            // remove answer
            answers.pages[page].questions.slice(question, question + 1);
          }
        }
      }
      return;
    }

    // find or create page
    var page = answers.pages.find(page => page.page === pageName);
    if (!page) {
      page = {
        page: pageName,
        questions: []
      };
      answers.pages.push(page);
    }

    // find or create question
    var question = page.questions.find(
      question => question.question === questionName
    );
    if (!question) {
      question = {
        question: questionName
      };
      page.questions.push(question);
    }

    const oldValue = question.value;
    if (value === "meta") {
      question.meta = meta;
    } else {
      question.value = value;
      question.meta = null;
    }

    return value !== oldValue;
  }

  function saveAnswers(finished) {
    // send answers to the server
    const { answers } = survey;
    const service = api.service("answers");

    if (finished) {
      delete answers["$unset"]; // remove traces of previous patches
      finished = new Date();
      finished.setMinutes(finished.getMinutes() - finished.getTimezoneOffset());
      answers.finished = finished;
    } else {
      answers["$unset"] = { finished: "" };
    }

    var promise = survey.answers._id
      ? service.patch(survey.answers._id, answers)
      : service.create({
          userId: user._id,
          surveyId,
          teamId,
          ...answers
        });
    return promise
      .then(result => {
        survey.answers = result;
        setSurvey({ ...survey });
        if (finished) {
          window.alert(translate("ANSWERS SENT"));
        } else {
          toast.success(translate("ANSWERS SAVED"));
        }
      })
      .catch(err => {
        window.alert(translate("SAVE FAILED") + "\n\n" + err);
      });
  }

  return survey ? (
    format ? (
      <SurveyPrint survey={survey} user={user} />
    ) : (
      <>
        <SurveyComponent
          survey={survey}
          setAnswer={setAnswer}
          saveAnswers={saveAnswers}
          teamId={teamId}
          translate={translate}
        />
      </>
    )
  ) : (
    <Spinner />
  );
}

Survey.propTypes = {
  user: PropTypes.object
};

// =========================================

function SurveyLink({ _id, title, translate, updatedAt }) {
  return (
    <>
      <Link to={"/results/" + _id}> {title}</Link> (
      {translate("UPDATED AT") + " "}
      {updatedAt})
    </>
  );
}

export function Surveys({ translate }) {
  const [surveys, setSurveys] = React.useState([]);

  React.useEffect(() => {
    api.load("surveys").then(resp => setSurveys(resp.data));
  }, []);

  return (
    <>
      <h1>{translate("SURVEYS")}</h1>
      <ul>
        {surveys.map(survey => (
          <li key={survey._id}>
            <SurveyLink {...survey} translate={translate} />
          </li>
        ))}
      </ul>
    </>
  );
}
