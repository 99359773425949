export function gradient(color1, color2, value) {
  // colors are rgb arrays
  // value between 0.0 and 1.0
  // returns rgb hex string
  var v1 = value;
  var v2 = 1 - v1;
  var rgb = [
    Math.round(color1[0] * v1 + color2[0] * v2),
    Math.round(color1[1] * v1 + color2[1] * v2),
    Math.round(color1[2] * v1 + color2[2] * v2)
  ];
  return (
    "#" +
    ("000000" + ((rgb[0] * 256 + rgb[1]) * 256 + rgb[2]).toString(16)).slice(-6)
  );
}

export const neutral = "#aaa";

export const GBR = ["olivedrab", "dodgerblue", "tomato"];
export const GOR = ["green", "orange", "red"];
export const GPS = ["gold", "purple", "slateblue"];

const opacity = 0.8;
export const transparants = [
  `rgba(255, 0, 0, ${opacity})`,
  `rgba(0, 0, 255,  ${opacity})`,
  `rgba(0, 255, 0, ${opacity})`,
  `rgba(0, 255, 255, ${opacity})`,
  `rgba(255,0, 255, ${opacity})`,
  `rgba(255, 255, 0, ${opacity})`
];

export default ["red", "slateblue", "yellow", "purple", "green", "orange"];
