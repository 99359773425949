import React from "react";

import Setting from "../components/Survey/Setting";

export function Demo({ translate }) {
  const setting = {
    title: "",
    name: "home",
    targets: [
      {
        label: translate(""),
        x: 0.2,
        y: 0.8
      },
      {
        label: translate("MY TEAM"),
        x: 0.8,
        y: 0.8
      },
      {
        label: translate("ORGANIZATION"),
        x: 0.5,
        y: 0.2
      }
    ],
    actors: [
      {
        label: translate("ME"),
        battery: true,
        x: 750,
        y: 50
      }
    ]
  };

  return <Setting value={setting} title="" name="" translate={translate} />;
}
