import React from "react";
import PropTypes from "prop-types";

import Circle from "./Circle";

export default function CircleWidget(props) {
  function onChange(domainName, seatName, color) {
    const value = [...props.value];
    var domain = value.find(dm => dm.domain === domainName);
    if (!domain) {
      domain = { domain: domainName, seats: [] };
      value.push(domain);
    }
    var seat = domain.seats.find(st => st.seat === seatName);
    if (!seat) {
      seat = {
        seat: seatName
      };
      domain.seats.push(seat);
    }
    seat.color = color;
    props.onChange(value);
  }
  return <Circle {...props} onChange={onChange} />;
}

CircleWidget.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object)
};

CircleWidget.defaultProps = {
  value: []
};
