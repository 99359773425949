import React from "react";
import { Link } from "react-router-dom";

import { Text } from "../components/Content";

function Team({ _id, name, translate, surveys }) {
  return (
    <li>
      {name}
      <ul>
        {surveys.length > 0 ? (
          surveys.map(survey => (
            <li key={survey._id}>
              <Link to={`/fillin/${_id}/${survey._id}`}> {survey.title}</Link>
            </li>
          ))
        ) : (
          <li>{translate("NO SURVEY")}</li>
        )}
      </ul>
    </li>
  );
}

export default function({ teams, permissions, coach, language, translate }) {
  if (permissions.surveys) {
    return teams ? (
      <>
        <Text name="IntroSurveys" locale={language} translate={translate} />
        <h3>{translate("SURVEYS")}</h3>
        <p>{translate("SURVEYS INTRO")}</p>
        <ul>
          {teams.map((team, nr) => (
            <Team key={nr} {...team} translate={translate} />
          ))}
        </ul>
      </>
    ) : (
      <p>{translate("NO TEAM")}</p>
    );
  }

  if (permissions.live) {
    return <Text name="IntroLive" locale={language} translate={translate} />;
  }

  return translate("NO ACCESS");
}
