import React from "react";
import PropTypes from "prop-types";

import {
  BarChart,
  Histogram,
  Scatter,
  labelTicks
} from "../../components/Chart";
import { transparants as colors } from "../../components/colors";

function questionTicks(question) {
  return labelTicks(
    question.type === "select" ? question.options : question.labels
  );
}

// =========================================

function BarGraph({ name, multi, options, answers, translate }) {
  if (!answers || !answers.length) {
    return translate("NO ANSWERS");
  }

  if (multi) {
    answers = answers.reduce((arr, val) => {
      return arr.concat(val.value);
    }, []);
  } else {
    answers = answers.map(a => a.value);
  }

  answers = options.map(option => answers.filter(a => a === option).length);

  return <BarChart x={options} y={answers} />;
}

// =========================================

function HistoGraph({ name, answers, labels, translate }) {
  if (!answers || !answers.length) {
    return translate("NO ANSWERS");
  }

  return (
    <Histogram
      name={name}
      data={answers.map(answer => answer.value)}
      labels={labels}
    />
  );
}

// =========================================

function ScatterGraph({ question, members, others, translate }) {
  var { x, y } = question.graph;
  var error = (
    <>
      <b>Grafiek</b> van {x || question.name}
    </>
  );

  x = x ? others.find(other => other.name === x) : question;
  if (!x) {
    return (
      <p>
        {error} en {y.join(", ")}: {translate("NO X")}
      </p>
    );
  } else if (!x.answers || !x.answers.length) {
    return (
      <>
        {error} en {y.join(", ")}: {translate("NO ANSWERS")}
      </>
    );
  }

  error = (
    <>
      {error} en {y.map(other => other.name)}:{" "}
      <b style={{ color: "red" }}>Onbekend</b>
    </>
  );
  y = y
    .map(name => others.find(other => other.name === name))
    .filter(other => other);
  if (!y.length) {
    return error;
  }

  function otherData(other, data, nr) {
    // returns extended copy of data and updates layout
    if (other && other.answers && other.answers.length) {
      return {
        ...data,
        name: other.name,
        x: x.answers.map(answer => answer.value),
        y: other.answers.map(answer => answer.value),
        text: members && other.answers.map(answer => answer.member)
      };
    }
  }

  const layout = {
    xaxis: {
      title: x.name,
      range: [0, 100],
      ...questionTicks(x)
    },
    yaxis: {
      title: y.length > 1 ? "zie legenda" : y[0].name,
      range: [0, 100],
      ...questionTicks(y[0])
    }
  };

  const data = y.map((other, nr) =>
    otherData(
      other,
      {
        type: "scatter",
        mode: "markers+text",
        marker: {
          color: "rgba(255, 255, 0, 0.001)",
          size: 100,
          gradient: { type: "radial", color: colors[nr] }
        },
        hoverinfo: "none"
      },
      nr
    )
  );
  // https://github.com/plotly/react-plotly.js/#refreshing-the-plot
  return <Scatter data={data} layout={layout} />;
}

ScatterGraph.propTypes = {
  x: PropTypes.object,
  y: PropTypes.arrayOf(PropTypes.object),
  members: PropTypes.bool
};

ScatterGraph.defaultProps = {
  y: [],
  members: false
};

// =========================================

export default function Graph(props) {
  const { type } = props.question.graph;
  if (type === "scatter") {
    return <ScatterGraph {...props} translate={props.translate} />;
  } else if (type === "histogram") {
    return <HistoGraph {...props.question} translate={props.translate} />;
  } else if (type === "bar") {
    return <BarGraph {...props.question} translate={props.translate} />;
  } else if (type === "none") {
    return ""; // ignore
  } else {
    return "Unknown graph type: " + type;
  }
}

Graph.propTypes = {
  question: PropTypes.object.isRequired,
  others: PropTypes.arrayOf(PropTypes.object),
  members: PropTypes.bool
};

Graph.defaultProps = {
  members: false
};
