import React from "react";
import PropTypes from "prop-types";

import { Button, FormGroup, Icon, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import Error from "../Errors";

import TextWidget from "./Text";
import NumberWidget from "./Number";
import BooleanWidget from "./Boolean";
import SelectWidget from "./Select";
import SettingWidget from "./Setting";
import CircleWidget from "../Circle";

export default function Question({
  children,
  confidential,
  type,
  required,
  answer,
  setAnswer,
  translate,
  ...props
}) {
  const { name } = props;
  const [value, setValue] = React.useState(answer.value);
  const [pass, setPass] = React.useState(answer.meta);

  React.useEffect(() => {
    setAnswer(name, answer.value);
  }, [name, answer, setAnswer]);

  if (type === "html") {
    return (
      <p
        style={{ paddingTop: "30pt" }}
        dangerouslySetInnerHTML={{ __html: props.html }}
      />
    );
  }

  if (type === "text" && confidential === undefined) {
    confidential = true;
  }

  const Widget = {
    text: TextWidget,
    boolean: BooleanWidget,
    number: NumberWidget,
    select: SelectWidget,
    setting: SettingWidget,
    circle: CircleWidget
  }[type];
  if (!Widget) return <Error>Unknown widget type "{type}"</Error>;

  let label = children || props.title;
  if (!label && type !== "setting") {
    return <Error>Question has no title.</Error>;
  }

  if (required) {
    label = (
      <span>
        <Icon
          icon="star"
          style={{
            color: value === undefined ? "red" : "grey",
            marginRight: "5pt"
          }}
        />
        {label}
      </span>
    );
  }

  return (
    <FormGroup className={"question " + (pass ? " passed" : "")} label={label}>
      <div className="answer">
        <Widget
          className="widget"
          value={value}
          onChange={val => {
            setValue(val);
            setAnswer(props.name, val);
            setPass(null);
          }}
          {...props}
        />
        {required || pass || props.disabled || (
          <Select
            className="pass"
            items={[
              translate("PASS"),
              translate("NOT APPLICABLE"),
              translate("DONT KNOW")
            ]}
            itemRenderer={(item, renderProps) => (
              <MenuItem
                active={renderProps.modifiers.active}
                key={renderProps.index}
                onClick={renderProps.handleClick}
                text={item}
              />
            )}
            filterable={false}
            activeItem={pass}
            onItemSelect={val => {
              setPass(val);
              setAnswer(props.name, "meta", val);
            }}
          >
            <Button
              text={pass || translate("NO ANSWER")}
              rightIcon="caret-down"
            />
          </Select>
        )}
      </div>
      {confidential && /*(
        /*<p style={{ marginTop: 15 }}>
          <Icon
            icon="star"
            style={{
              marginRight: "5pt"
            }}
          />
          {translate("INVISIBLE")}
        </p>
          )*/<></>}
    </FormGroup>
  );
}

Question.propTypes = {
  type: PropTypes.oneOf([
    "html",
    "boolean",
    "number",
    "select",
    "text",
    "setting",
    "circle"
  ]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  answer: PropTypes.object,
  setAnswer: PropTypes.func
};

Question.defaultProps = {
  type: "text",
  disabled: false,
  required: false,
  answer: { value: undefined, meta: undefined }
};
