import React from "react";

import { SelectField } from "./Form";

export function Languages({ language, setLanguage, languages }) {
  return (
    <SelectField
      items={languages.languages}
      value={language}
      translate={() => {}}
      onChange={data => setLanguage(data.target.value)}
    />
  );
}
