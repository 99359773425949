import React from "react";

import PropTypes from "prop-types";
import { NumericInput, Slider } from "@blueprintjs/core";

export default function NumberWidget({
  widget,
  labels,
  numbers,
  colors,
  ...props
}) {
  // props.value = props.value || (props.max + props.min) / 2;
  props.value = props.value || props.min;
  if (widget === "field") {
    delete props.labelStepSize;
    return <NumericInput {...props} />;
  }

  if (numbers) {
    labels = false;
  } else {
    if (labels && !labels.length) {
      labels = NumberWidget.defaultProps.labels;
    }
    if (labels.length) {
      const size = (props.max - props.min) / (labels.length - 1);
      props = {
        ...props,
        labelRenderer: val => labels[val / size],
        labelStepSize: size
      };
    } else {
      props.labelRenderer = false;
    }
  }
  if (colors) {
    props.className += " gradient-" + colors;
  }

  props.showTrackFill = false;

  return <Slider {...props} />;
}

NumberWidget.propTypes = {
  name: PropTypes.string.isRequired,
  widget: PropTypes.oneOf(["field", "slider"]),
  labels: PropTypes.arrayOf(PropTypes.string),
  min: PropTypes.number,
  max: PropTypes.number,
  labelStepSize: PropTypes.number,
  numbers: PropTypes.bool,
  colors: PropTypes.string
};

NumberWidget.defaultProps = {
  widget: "slider",
  labels: ["niet", "helemaal"],
  min: 0,
  max: 100,
  labelStepSize: 10,
  numbers: false,
  colors: "red-green"
};
