import React from "react";

import { Link, useParams } from "react-router-dom";
import { Spinner } from "@blueprintjs/core";

import { api } from "../feathers";

import { Survey, Print } from "../components/Report/Survey";

// =========================================

export function Report({ format, translate }) {
  const { teamId, surveyId } = useParams();
  const [report, setReport] = React.useState(undefined);

  React.useEffect(() => {
    api
      .get("results", surveyId, { teams: [teamId] })
      .then(resp => setReport(resp));
  }, [teamId, surveyId]);

  return (
    <>
      <h1>{translate("RESULTS")}</h1>
      {report ? (
        format ? (
          <Print report={report} translate={translate} />
        ) : (
          <Survey {...report} translate={translate} />
        )
      ) : (
        <Spinner />
      )}
    </>
  );
}

// =========================================

function Answers({ survey, teams }) {
  return (
    <li>
      {survey.title} - v{survey.version}
      <ul>
        {teams.map((team, nr) => (
          <li key={nr}>
            <Link to={`/results/${team._id}/${survey._id}`}>{team.name}</Link>
          </li>
        ))}
      </ul>
    </li>
  );
}

export function Reports({ translate, ...props }) {
  const [reports, setReports] = React.useState([]);

  React.useEffect(() => {
    api.load("results").then(resp => {
      setReports(resp || []);
    });
  }, []);

  return (
    <>
      <h1>{translate("RESULTS")}</h1>
      <ul>
        {reports.length
          ? reports.map((answers, nr) => <Answers key={nr} {...answers} />)
          : translate("NO RESULTS")}
      </ul>
    </>
  );
}

// =========================================
