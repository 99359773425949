import React from "react";

import PropTypes from "prop-types";
import { Button, MenuItem } from "@blueprintjs/core";
import { MultiSelect, Select } from "@blueprintjs/select";

function SingleSelectWidget({ value, onChange, ...props }) {
  const [selection, setSelection] = React.useState(value);

  return (
    <Select
      {...props}
      activeItem={selection}
      onItemSelect={val => {
        onChange(val);
        setSelection(val);
      }}
    >
      <Button
        text={selection || "Maak een keuze"}
        rightIcon="caret-down"
        style={{ marginLeft: "5pt" }}
      />
    </Select>
  );
}

function MultiSelectWidget({ value, ...props }) {
  const [selection, setSelection] = React.useState(value);

  function onChange(items) {
    setSelection(items);
    props.onChange(items.length ? items : undefined);
  }

  function deselectItem(item) {
    onChange(selection.filter(value => value !== item));
  }

  function clickItem(item) {
    if (selection.includes(item)) {
      deselectItem(item);
    } else {
      onChange([...selection, item]);
    }
  }

  return (
    <MultiSelect
      {...props}
      selectedItems={selection}
      onItemSelect={clickItem}
      tagRenderer={tag => tag}
      tagInputProps={{ onRemove: deselectItem }}
    />
  );
}

MultiSelectWidget.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string)
};

MultiSelectWidget.defaultProps = {
  value: []
};

export default function SelectWidget({
  name,
  widget,
  multi,
  options,
  children,
  ...props
}) {
  function itemRenderer(item, renderProps) {
    return (
      <MenuItem
        active={renderProps.modifiers.active}
        key={renderProps.index}
        onClick={renderProps.handleClick}
        text={item}
      />
    );
  }

  const widgetProps = {
    ...props,
    name: name,
    items: options,
    itemRenderer: itemRenderer,
    filterable: false
  };

  const Widget = multi ? MultiSelectWidget : SingleSelectWidget;

  return <Widget {...widgetProps} />;
}

SelectWidget.propTypes = {
  name: PropTypes.string.isRequired,
  widget: PropTypes.oneOf(["dropdown"]),
  multi: PropTypes.bool,
  options: PropTypes.array.isRequired
};

SelectWidget.defaultProps = {
  widget: "dropdown",
  multi: false,
  options: []
};
