import React from "react";
import PropTypes from "prop-types";

import SettingComponent from "../Setting/Setting";
import { savePosition } from "../dnd";
import { radius, settingProps } from "../Setting/ShapeProps";
import { api } from "../../feathers";
import { isElevated } from "../../utils";

export default function Setting({
  user,
  targets,
  name,
  settings,
  translate,
  ...props
}) {
  var y = 50;
  const elevated = user && (isElevated(user) || user._id === props.host);
  const blocked = settings.blocked && !elevated;
  const zoom = settings.zoom ? 1.5 : 1;

  function actors2state(newActors) {
    return newActors.map(actor => {
      if (actor.x === undefined) {
        actor.x = settingProps.width + radius + 15;
        actor.y = y;
        y += 70;
      }
      const stolen = actor.ownerId && user && actor.ownerId !== user._id;
      const member =
        elevated || !user || (actor.member && actor.member === user._id);
      actor.blocked = stolen || blocked || !member;

      return actor;
    });
  }

  function updateActor(nr, value, save) {
    // transform to mongo
    const actor = `actors.${nr}`;
    value = Object.keys(value).reduce((obj, key) => {
      obj[`${actor}.${key}`] = value[key];
      return obj;
    }, {});

    // throttle updates to the database
    if (save) {
      api.save("live", value, props._id, { silent: true });
    }
  }

  const data = {
    actors: actors2state(props.actors),
    updateActor,
    targets
  };

  return (
    <>
      {user !== undefined || <h1>{translate("LIVE CONSTELLATION")}</h1>}
      <h3>
        {name} {settings.blocked && "(" + translate("BLOCKED") + ")"}
      </h3>
      <SettingComponent
        name={name}
        title={name}
        {...props}
        zoom={zoom}
        data={data}
        dnd={{
          onSelect: (state, nr) => {
            api.save(
              "live",
              {
                ["actors." + nr + ".ownerId"]: state ? user && user._id : null
              },
              props._id,
              { silent: true }
            );
          },
          onDrop: (e, nr) =>
            updateActor(
              nr,
              {
                ...savePosition(
                  e,
                  { ...settingProps, width: settingProps.width + radius + 25 },
                  radius / 2,
                  zoom
                ),
                ownerId: null
              },
              true
            )
        }}
      />
    </>
  );
}

Setting.propTypes = {
  name: PropTypes.string.isRequired,
  actors: PropTypes.arrayOf(PropTypes.object),
  targets: PropTypes.arrayOf(PropTypes.object)
};

Setting.defaultProps = {
  actors: [],
  targets: [],
  settings: {}
};
