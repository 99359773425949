import React from "react";

import { Button } from "@blueprintjs/core";
import { Cell, EditableCell, Column, Table } from "@blueprintjs/table";
import "@blueprintjs/table/lib/css/table.css";
import { SelectField } from "./Form";

import { isElevated, PIN } from "../utils";
import { api } from "../feathers";

// ========================================

export function UserTable({ collection, setPage, user, translate, languages }) {
  const columns = [
    {
      name: translate("NAME"),
      cellRenderer: row => (
        <EditableCell
          onConfirm={value =>
            api.save("users", { name: value }, collection[row]._id)
          }
          value={collection[row].name || ""}
        />
      )
    },
    {
      name: translate("EMAIL"),
      cellRenderer: row => (
        <EditableCell
          onConfirm={value =>
            api.save("users", { email: value }, collection[row]._id)
          }
          value={collection[row].email}
        />
      )
    },
    {
      name: translate("LANGUAGE"),
      cellRenderer: row => (
        <Cell>
          {isElevated(user) ? (
            <SelectField
              key={collection[row]._id}
              items={languages}
              translate={translate}
              value={collection[row].language || languages[0]}
              onChange={data =>
                api.save(
                  "users",
                  { language: data.target.value },
                  collection[row]._id
                )
              }
            />
          ) : (
            collection[row].language
          )}
        </Cell>
      )
    },
    {
      name: translate("ROLE"),
      cellRenderer: row => (
        <Cell>
          {isElevated(user) ? (
            <SelectField
              key={collection[row]._id}
              items={["coach", "respondent"]}
              translate={translate}
              value={collection[row].role}
              onChange={data =>
                api.save(
                  "users",
                  { role: data.target.value },
                  collection[row]._id
                )
              }
            />
          ) : (
            collection[row].role
          )}
        </Cell>
      )
    },
    {
      name: translate("ACTIONS"),
      cellRenderer: row => (
        <Cell>
          <Button
            name="new_password"
            onClick={() => {
              if (
                window.confirm(
                  `${translate("CONFIRM PASSWORD FOR")} ${
                    collection[row].email
                  }?`
                )
              ) {
                const password = PIN();
                api
                  .save("users", { password }, collection[row]._id)
                  .then(user => {
                    setPage(-1);
                    window.alert(
                      `${translate("NEW PASSWORD FOR")} ${
                        user.email
                      }: ${password}`
                    );
                  });
              }
            }}
            icon="key"
            small
          ></Button>
          {isElevated(user, "coach") && (
            <Button
              name="delete"
              onClick={() => {
                api.hide("users", collection[row]._id, {
                  email: collection[row].email + " " + new Date().toISOString()
                });
              }}
              icon="cross"
              small
            ></Button>
          )}
        </Cell>
      )
    }
  ];

  if (isElevated(user)) {
    columns.splice(3, 0, {
      name: translate("ADDED BY"),
      cellRenderer: row => <Cell>{collection[row].coach}</Cell>
    });
  }

  return (
    <Table numRows={collection.length}>
      {columns.map(column => (
        <Column key={column.name} {...column} />
      ))}
    </Table>
  );
}

// ========================================
