import React from "react";
import PropTypes from "prop-types";

import SettingComponent from "../Setting/Setting";
import colors from "../colors";

function mergeTarget(target, answers) {
  return {
    ...target,
    value: answers.reduce((obj, answer) => {
      if (obj[answer.value]) {
        obj[answer.value] += 1;
      } else {
        obj[answer.value] = 1;
      }
      return obj;
    }, {})
  };
}

function mergeTargets(targets, answers) {
  return targets.map(target =>
    mergeTarget(
      target,
      answers.map(answer =>
        answer.find(targetAnswer => targetAnswer.label === target.label)
      )
    )
  );
}

export default function Setting(props) {
  var actors = [];
  if (props.answers.length) {
    // collect actors
    actors = props.answers.reduce((arr, answer, nr) => {
      if (answer.value) {
        return arr.concat(
          answer.value.actors.map(actor => ({
            ...actor,
            label: "" + (nr + 1),
            color: colors[nr + 1]
          }))
        );
      }
      return arr;
    }, []);

    // calculate average energy for each target
    const answers = props.answers
      .filter(answer => answer.value && answer.value.targets)
      .map(answer => answer.value.targets);
    const targets = mergeTargets(props.targets, answers);

    return <SettingComponent {...props} data={{ actors, targets }} />;
  }
  return props.translate("NO ANSWERS");
}

Setting.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
  targets: PropTypes.arrayOf(PropTypes.object)
};

Setting.defaultProps = {
  answers: [],
  targets: []
};
