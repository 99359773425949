import React from "react";


import {  SurveyEditor } from "../components/Survey";


import demo from "./demo.json";

// =========================================

export function Sandbox({ locale, setLanguage, ...props }) {
    React.useEffect(() => {
      if (locale && setLanguage) {
        setLanguage(locale);
      }
    });
  
    return <SurveyEditor survey={demo} {...props} />;
  }
  
  // =========================================