import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Prompt } from "react-router";

import { Icon, Button, Tab, Tabs } from "@blueprintjs/core";

import FileLoader from "../FileLoader";
import { findDuplicates } from "../../utils";
import { api } from "../../feathers";

import Page from "./Page";
import "./survey.css";

// =================================================

export function fullSurveyName(survey) {
  return `${survey.title} (${survey.version} - ${survey.locale})`;
}

// =================================================

export function Survey({ survey, setAnswer, saveAnswers, teamId, translate }) {
  const [tab, setTab] = React.useState(-1);
  const [changed, setChanged] = React.useState(
    survey.answers.finished ? "finished" : undefined
  );
  const { pages } = survey;
  const last = tab >= pages.length - 1;

  function savePage(finished) {
    saveAnswers(finished).then(() => {
      if (last) {
        setTab(0);
      } else {
        setTab(tab + 1);
      }
      setChanged(finished ? "finished" : undefined);
      window.scrollTo(0, 0);
    });
  }

  function setAnswerChanged(...props) {
    if (setAnswer(...props) && !changed) {
      setChanged("changed");
    }
  }

  var instructions = [];
  var buttons = [];
  if (tab >= 0) {
    instructions.push(`${translate("MODULE")} ${pages[tab].name}. `);

    if (survey.answers && survey.answers.finished) {
      instructions.push(translate("ALREADY DONE"));
    } else {
      instructions.push(
        `${translate("CHOOSE BELOW")} "${translate("SAVE")}" ${translate(
          "AND CONTINUE"
        )} ${pages[last ? 0 : tab + 1].name}. `
      );
      buttons.push(
        <Button icon="archive" key="save" onClick={() => savePage()}>
          {translate("SAVE")}
        </Button>
      ); 
      if (last) {
        instructions.push(
          `${translate("CHOOSE BELOW")} "${translate("SEND")}" ${translate(
            "AND DONE"
          )}`
        );
        buttons.push(
          <Button icon="envelope" key="send" onClick={() => savePage(true)}>
            {translate("SEND")}
          </Button>
        );
      }
    }
  }

  return (
    <SurveyPanel
      {...survey}
      setAnswer={setAnswerChanged}
      teamId={teamId}
      tab={tab}
      setTab={setTab}
      translate={translate}
    >
      <Prompt
        when={changed !== "finished"}
        message={
          changed === "changed" ? translate("UNSAVED") : translate("UNSENT")
        }
      />
      {instructions}
      <hr />
      {buttons}
    </SurveyPanel>
  );
}

Survey.propTypes = {
  survey: PropTypes.object.isRequired,
  setAnswer: PropTypes.func.isRequired,
  saveAnswers: PropTypes.func.isRequired
};

// =================================================

function SurveyPanel({
  title,
  description,
  pages,
  answers,
  setAnswer,
  tab,
  setTab,
  children,
  teamId,
  translate,
  ...props
}) {
  return (
    <>
      <h1>{title}</h1>
      <p>
        TeamConnect {translate("CONSISTS OF")} {pages.length}{" "}
        {translate("MODULES LIST")}:
      </p>
      <table className="compact">
        <tbody>
          {pages.map((page, nr) => (
            <tr key={nr}>
              <td>{page.name}</td>
              <td>{page.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
      {teamId && (
        <div style={{ textAlign: "right" }}>
          <Link to={`/print/questions/${teamId}/${props._id}`}>
            <Icon icon="print" /> {translate("PRINT")}
          </Link>
        </div>
      )}
      <p>
        {translate("CLICK MODULE")}{" "}
        {answers && answers.finished ? translate("VIEW") : translate("FILL IN")}
        .
      </p>
      <Tabs id="survey" selectedTabId={tab} onChange={setTab}>
        {pages.map((page, nr) => (
          <Tab
            id={nr}
            key={nr}
            title={page.name}
            panel={
              <Page
                {...page}
                translate={translate}
                disabled={answers && answers.finished !== undefined}
                answers={
                  answers &&
                  answers.pages.find(
                    pageAnswers => pageAnswers.page === page.name
                  )
                }
                setAnswer={setAnswer}
              />
            }
          />
        ))}
      </Tabs>
      {children}
    </>
  );
}

SurveyPanel.propTypes = {
  setAnswer: PropTypes.func,
  answers: PropTypes.object
};

SurveyPanel.defaultProps = {
  setAnswer: () => {},
  answers: { pages: [] }
};

// =================================================

export function SurveyEditor({ survey, allowUpload, locale, translate }) {
  const [surveyData, setSurvey] = React.useState(survey);
  const [loaded, setLoaded] = React.useState(false);
  const [errors, setErrors] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  function check(data) {
    // check a survey for errors
    const errors = [];

    data.pages.forEach(page => {
      // ignore html questions
      const realQuestions = page.questions.filter(
        question => question.type !== "html"
      );

      // check if all questions have names
      const noNames = realQuestions.filter(
        question => !(question.name && question.name !== "")
      );
      if (noNames.length > 0) {
        errors.push(
          `Page ${page.name} contains questions without a name: ${noNames
            .map(question => question.title)
            .join(", ")}`
        );
      }

      // check for duplicate names
      const questions = realQuestions.map(question => question.name);
      const duplicates = findDuplicates(questions);
      if (duplicates.length > 0) {
        errors.push(
          `Duplicates on page ${page.name}: ${duplicates.join(", ")}`
        );
      }

      // check references
      function checkRef(ref) {
        if (!questions.includes(ref)) {
          errors.push(`Undefined reference "${ref}" on page "${page.name}".`);
        }
      }

      page.questions.forEach(question => {
        if (question.graph) {
          checkRef(question.graph.x);
          if (Array.isArray(question.graph.y)) {
            question.graph.y.forEach(checkRef);
          } else {
            checkRef(question.graph.y);
          }
        }
      });
    });

    return errors.length > 0 && errors;
  }

  function loadFile(text) {
    const data = JSON.parse(text);
    setErrors(check(data));
    if (!errors) {
      setSurvey(data);
    }
    setLoaded(!errors);
  }

  function saveSurvey() {
    if (!errors) {
      api
        .load("surveys", {
          title: surveyData.title,
          version: surveyData.version,
          locale: surveyData.locale
        })
        .then(surveys => {
          if (surveys && surveys.total) {
            if (
              window.confirm(
                `${translate("SURVEY")} "${fullSurveyName(
                  surveyData
                )}" ${translate("OVERWRITE")}`
              )
            ) {
              api.save("surveys", surveyData, surveys.data[0]._id);
            }
          } else {
            api.save("surveys", surveyData);
          }
        });
    }
  }

  return (
    <>
      {errors ? (
        errors.map(err => <li key={err}>{err}</li>)
      ) : (
        <SurveyPanel
          {...surveyData}
          tab={tab}
          setTab={setTab}
          translate={translate}
        />
      )}
      <hr style={{ marginTop: "75pt" }} />
      {loaded && (
        <Button onClick={saveSurvey}>{translate("SAVE SURVEY")}</Button>
      )}
      {allowUpload && (
        <FileLoader
          translate={translate}
          content={JSON.stringify(surveyData, null, 2)}
          onChange={loadFile}
        />
      )}
    </>
  );
}

SurveyEditor.propTypes = {
  survey: PropTypes.object.isRequired
};

SurveyEditor.defaultProps = {
  allowUpload: false
};

// =================================================
