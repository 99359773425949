export function eventPosition({ target, evt }, zoom = 1) {
  // returns x, y position of event, for both mouse and touch events
  var position = null;
  if (evt.changedTouches) {
    const stage = target.getStage();
    const scale = stage.attrs.scaleX;
    position = stage.getPointerPosition();
    position = { x: position.x / scale, y: position.y / scale };
  } else {
    position = { x: evt.layerX, y: evt.layerY };
  }
  return { x: position.x / zoom, y: position.y / zoom };
}

export function savePosition(e, dim, margin, zoom = 1) {
  const pos = eventPosition(e, zoom);
  pos.x = Math.max(pos.x, margin);
  pos.y = Math.max(pos.y, margin);
  pos.x = Math.min(pos.x, dim.width - margin);
  pos.y = Math.min(pos.y, dim.height - margin);
  return pos;
}

export function distance(pos1, pos2) {
  // returns the distance between two points
  return Math.sqrt(Math.pow(pos2.x - pos1.x, 2) + Math.pow(pos2.y - pos1.y, 2));
}
