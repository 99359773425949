import React from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "@blueprintjs/core";

import { api } from "../feathers";
import { hasPermission } from "../utils";
import { ControlledForm } from "../components/Form";
import { PaginatedCollection } from "../components/Pagination";
import { TeamTable, MemberTable, SurveyTable } from "../components/Team";
import { fullSurveyName } from "../components/Survey";

// ================================

export function Members({ translate }) {
  const [team, setTeam] = React.useState();
  const { teamId } = useParams();

  React.useEffect(() => {
    function loadTeam() {
      api.get("teams", teamId).then(teamResp => {
        const memberIds = teamResp.members.map(member => member._id);
        api
          .load("users", {
            _id: { $nin: memberIds },
            hidden: { $ne: true },
            $select: ["email"],
            $limit: -1
          })
          .then(candidates => {
            setTeam({ ...teamResp, candidates });
          });
      });
    }

    function loadTeamConditional(data) {
      if (data._id === teamId) {
        loadTeam();
      }
    }

    loadTeam();

    api.setListener("teams", "patched", loadTeamConditional);

    return () => {
      api.removeListener("teams", "patched", loadTeamConditional);
    };
    // eslint-disable-next-line
  }, []);

  return team ? (
    <>
      <h1>
        {translate("MEMBERS OF TEAM")} "{team.name}"
      </h1>
      <MemberTable team={team} translate={translate} />
    </>
  ) : (
    <Spinner />
  );
}

// ================================

export function TeamSurveys({ translate }) {
  const [team, setTeam] = React.useState();
  const [surveys, setSurveys] = React.useState();
  const { teamId } = useParams();

  React.useEffect(() => {
    function loadTeam() {
      api.get("teams", teamId).then(teamResp => {
        setTeam(teamResp);
        const surveyIds = teamResp.surveys.map(survey => survey._id);
        api
          .load("surveys")
          .then(surveyResp =>
            setSurveys(
              surveyResp.data.filter(survey => !surveyIds.includes(survey._id))
            )
          );
      });
    }

    function loadTeamConditional(data) {
      if (data._id === teamId) {
        loadTeam();
      }
    }

    loadTeam();

    api.setListener("teams", "patched", loadTeamConditional);

    return () => {
      api.removeListener("teams", "patched", loadTeamConditional);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {team && surveys ? (
        <>
          <h1>
            {translate("SURVEYS OF TEAM")} "{team.name}"
          </h1>
          <SurveyTable team={team} surveys={surveys} translate={translate} />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

// ================================

export function Teams({ user, translate }) {
  const [surveys, setSurveys] = React.useState([]);

  React.useEffect(() => {
    api.load("surveys").then(resp => {
      setSurveys(resp);
    });
  }, []);

  const fields = [
    {
      name: "name",
      label: translate("NAME"),
      type: "text",
      value: ""
    }
  ];

  if (hasPermission(user, "surveys", "write")) {
    fields.push({
      name: "survey",
      label: translate("SURVEY"),
      translate,
      type: "select",
      items: surveys,
      itemRenderer: fullSurveyName,
      value: surveys.find(survey => survey.locale === user.language)
    });
    fields.push({
      name: "allowAccess",
      label: translate("REPORT"),
      translate,
      type: "boolean",
      value: false
    });
  }

  if (hasPermission(user, "live", "write")) {
    fields.push({
      name: "allowLive",
      label: translate("LIVE"),
      translate,
      type: "boolean",
      value: false
    });
    fields.push({
      name: "public",
      label: translate("PUBLIC"),
      translate,
      type: "boolean",
      value: false
    });
  }

  return (
    <>
      <h1>{translate("TEAMS")}</h1>
      {surveys.length > 0 && (
        <>
          <PaginatedCollection
            service="teams"
            translate={translate}
            query={{ $sort: { name: 1 } }}
            table={TeamTable}
            tableOptions={{ user, surveys, translate }}
            filter={(query, value) => (query.name = { $regex: value })}
          />
          <ControlledForm
            title={translate("ADD TEAM")}
            fields={fields}
            put={data => {
              const team = {
                ...data,
                coachId: user._id
              };
              if (data.survey) {
                team.surveys = [data.survey._id];
                delete team.survey;
              }
              api.save("teams", team);
            }}
            submit={translate("ADD")}
          />
        </>
      )}
    </>
  );
}

// ================================
