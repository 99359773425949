import React from "react";
import PropTypes from "prop-types";

import { Stage, Layer, Rect } from "react-konva";

import Error from "../Errors";

import Domain from "./Domain";

export default function Circle({
  name,
  domains,
  width,
  height,
  onChange,
  value,
  ...props
}) {
  const opacity = props.disabled ? 0.5 : 1.0;
  if (!domains.length) return <Error>Circle {name} has no domains.</Error>;
  const seats = domains.reduce(
    (total, domain) => total + domain.seats.length,
    0
  );
  const opening = 40;
  const part = (360 - opening) / seats;

  // set rotation and arc for domains
  var count = 0;
  domains.forEach(domain => {
    const angle = part * domain.seats.length;
    domain.angle = angle - 5;
    domain.rotation = -(opening / 2 + count * part) - 85 - angle;
    count += domain.seats.length;
  });

  return (
    <Stage x={0} y={0} width={width + 150} height={height} opacity={opacity}>
      <Layer>
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="gainsboro"
          // onTouch={unselect}
        />
        {domains.map((domain, nr) => (
          <Domain
            key={nr}
            {...domain}
            x={width / 2}
            y={height / 2}
            value={
              value && value.length > 0
                ? value.find(val => val.domain === domain.name)
                : undefined
            }
            onChange={
              props.disabled
                ? undefined
                : (seat, color) => onChange(domain.name, seat, color)
            }
          />
        ))}
      </Layer>
    </Stage>
  );
}

Circle.propTypes = {
  name: PropTypes.string.isRequired,
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.object)
};

Circle.defaultProps = {
  width: 700,
  height: 700,
  onChange: () => {},
  value: []
};
