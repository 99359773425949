import React from "react";

import PropTypes from "prop-types";
import { Checkbox, Switch } from "@blueprintjs/core";

export default function BooleanWidget({ name, widget, children, ...props }) {
  props.label = children;

  const Widget = {
    checkbox: Checkbox,
    switch: Switch
  }[widget];

  return <Widget {...props} />;
}

BooleanWidget.propTypes = {
  name: PropTypes.string.isRequired,
  widget: PropTypes.oneOf(["checkbox", "switch"])
};

BooleanWidget.defaultProps = {
  widget: "switch"
};
