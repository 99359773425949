import React from "react";

import { Arc } from "react-konva";

import { radius, wedgeProps } from "./ShapeProps";
import { distance, eventPosition } from "../dnd";

export default function View({
  angle,
  innerRadius,
  interactive,
  updateActor,
  rotation,
  setActiveView,
  opacity
}) {
  const wedgeRef = React.useRef();
  const [stroke, setStroke] = React.useState("black");
  const view = { angle, rotation };
  let lag = 0;

  function followMove(pointer, props) {
    const position = wedgeRef.current.absolutePosition();
    const dist = distance(position, pointer);

    props.rotation =
      (Math.atan2(pointer.y - position.y, pointer.x - position.x) * 180) /
      Math.PI;

    props.angle = Math.min(
      360 - wedgeProps.angle,
      (dist * wedgeProps.angle) / radius
    );
  }

  function viewStartDrag() {
    setStroke("red");

    setActiveView({
      wedgeRef,
      onEnd(e) {
        setActiveView(null);
        setStroke("black");
        updateActor(view, true);
      },
      onMove(e) {
        followMove(eventPosition(e), view);
        updateActor({ ...view }, lag % 10 === 0);
        lag += 1;
      }
    });
  }

  var arcProps = {
    ...wedgeProps,
    ...view,
    stroke,
    outerRadius: innerRadius * 1.75,
    innerRadius,
    fill: `rgba(255,255,255,${opacity})`
  };
  delete arcProps.radius;
  arcProps.rotation = arcProps.rotation - arcProps.angle / 2;

  const dndProps = interactive && {
    onMouseDown: viewStartDrag,
    onTouchStart: viewStartDrag
  };

  return <Arc ref={wedgeRef} {...arcProps} {...dndProps} />;
}

View.defaultProps = {
  opacity: 1.0
};
