import React from "react";
import PropTypes from "prop-types";

import { Stage, Layer, Rect } from "react-konva";

import Error from "../Errors";
import "./setting.css";
import { settingProps } from "./ShapeProps";
import Actor from "./Actor";
import Target from "./Target";

/** shows an static (non-interactive) setting */
export default function Setting({
  width,
  height,
  fullWidth,
  scale,
  data,
  dnd,
  value, //silence konva warning
  zoom = 1,
  ...props
}) {
  const [activeView, setActiveView] = React.useState(null);

  if (!data.targets) return <Error>Setting has no targets</Error>;
  if (!data.actors) return <Error>Setting has no actors</Error>;
  const interactive = data && dnd;

  function actorProps(actor, nr) {
    return (
      interactive &&
      !props.disabled &&
      !activeView &&
      !actor.blocked && {
        interactive,
        ...dnd,
        setActiveView,
        onDrop: e => dnd.onDrop(e, nr),
        onSelect: dnd.onSelect ? state => dnd.onSelect(state, nr) : () => { },
        updateActor: (value, forceSave) =>
          data.updateActor(nr, value, forceSave),
        zoom
      }
    );
  }

  function targetProps(nr) {
    return (
      interactive && {
        interactive,
        onChange: value => data.updateTarget(nr, value)
      }
    );
  }

  fullWidth -= width;
  width *= zoom;
  fullWidth += width;
  height *= zoom;

  return (
    <Stage
      x={0}
      y={0}
      width={fullWidth}
      height={height}
      scale={{ x: scale, y: scale }}
    >
      <Layer>
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="gainsboro"
          cornerRadius={8}
        />
        {data.targets.map((target, nr) => (
          <Target
            key={nr}
            nr={nr}
            {...props}
            {...target}
            {...targetProps(nr)}
            x={target.x * width}
            y={target.y * height}
          />
        ))}
        {data.actors.map((actor, nr) => (
          <Actor key={nr} {...props} {...actorProps(actor, nr)} {...actor}
            x={actor.x * zoom}
            y={actor.y * zoom}
          />
        ))}
        {activeView && (
          <Rect /* catch events */
            x={0}
            y={0}
            width={fullWidth}
            height={height}
            fill="grey"
            opacity={0}
            {...props}
            onMouseMove={activeView.onMove}
            onTouchMove={activeView.onMove}
            onMouseUp={activeView.onEnd}
            onTouchEnd={activeView.onEnd}
          />
        )}
      </Layer>
    </Stage>
  );
}

Setting.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number
};

Setting.defaultProps = {
  ...settingProps
};
