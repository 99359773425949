import React from "react";
import PropTypes from "prop-types";

import { Image } from "react-konva";

export const levels = [undefined, "full", "half", "empty"];

export function Battery({ level }) {
  const [battery, setBattery] = React.useState();
  React.useEffect(() => {
    if (level) {
      const image = new window.Image();
      image.src = `/battery-${level}.png`;
      image.onload = () => {
        setBattery(image);
      };
    } else {
      setBattery(level);
    }
  }, [level]);

  return <Image image={battery} scaleX={0.37} scaleY={0.3} x={-33} />;
}

Battery.propTypes = {
  level: PropTypes.string
};
