import React from "react";

import PropTypes from "prop-types";

import Error from "../Errors";
import Question from "./Question";

export default function Page({
  name,
  title,
  description,
  disabled,
  questions,
  answers,
  setAnswer,
  translate
}) {
  return (
    <div>
      <h4>{title}</h4>
      {description ? (
        <p dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        ""
      )}
      {questions.length > 0 ? (
        questions.map((question, nr) => (
          <Question
            key={question.name || "html" + nr}
            {...question}
            translate={translate}
            answer={answers.questions.find(
              answer => answer.question === question.name
            )}
            setAnswer={(question, value, meta) => {
              setAnswer(name, question, value, meta);
            }}
            disabled={disabled}
          />
        ))
      ) : (
        <Error>Page contains no questions</Error>
      )}
    </div>
  );
}

Page.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.object),
  answers: PropTypes.object,
  pagination: PropTypes.func,
  setAnswer: PropTypes.func
};

Page.defaultProps = {
  questions: [],
  answers: { questions: [] },
  disabled: false
};
