import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";
import { Button } from "@blueprintjs/core";

import { ControlledForm } from "../components/Form";
import { api } from "../feathers";

// ========================================

export function Editor({ translate }) {
  const [text, setText] = React.useState();
  const { textId } = useParams();

  React.useEffect(() => {
    api.get("texts", textId).then(resp => {
      if (resp) {
        resp.content = RichTextEditor.createValueFromString(
          resp.content,
          "html"
        );
        setText(resp);
      }
    });
  }, [textId]);

  return (
    <>
      {text && (
        <>
          <h1>{text.name}</h1>
          <RichTextEditor
            value={text.content}
            onChange={content => setText({ ...text, content })}
          />
          <Button
            style={{ marginTop: "10pt" }}
            onClick={() =>
              api.save(
                "texts",
                { ...text, content: text.content.toString("html") },
                textId
              ) 
            }
          >
            {translate("SAVE")}
          </Button>
        </>
      )}
    </>
  );
}

// ========================================

export function Texts({ user, translate, languages }) {
  const [texts, setTexts] = React.useState([]);
  const [missing, setMissing] = React.useState([]);

  function findUnique(array, field) {
    return array
      ? array
          .filter(
            (element, index) =>
              array.findIndex(el => el[field] === element[field]) === index
          )
          .map(obj => obj[field])
      : [];
  }

  const renderTitle = text => `${text.name} (${text.locale})`;

  React.useEffect(() => {
    function loadTexts() {
      api.load("texts", { $limit: -1, $sort: "locale name" }).then(resp => {
        if (resp) {
          setTexts(resp);

          const names = findUnique(resp, "name");
          setMissing(
            names.reduce(
              (acc, name) => [
                ...acc,
                ...languages
                  .filter(locale => {
                    return !resp.find(
                      text => text.name === name && text.locale === locale
                    );
                  })
                  .map(locale => ({ name, locale }))
              ],
              []
            )
          );
        }
      });
    }

    loadTexts();
    api.setListener("texts", "created", loadTexts);

    return () => api.removeListener("texts", "created");
  }, [languages]);

  return (
    <>
      <h1>{translate("TEXTS")}</h1>
      {texts && texts.length > 0
        ? texts.map((text, nr) => (
            <li key={nr}>
              <Link to={`/text/${text._id}`}>{renderTitle(text)}</Link>
            </li>
          ))
        : "Geen teksten gevonden"}
      {missing.length > 0 && (
        <ControlledForm
          key={missing.length} // force redraw when a text has been added
          fields={[
            {
              type: "select",
              name: "text",
              translate,
              label: translate("ADD TEXT"),
              items: missing,
              value: missing[0],
              itemRenderer: renderTitle
            }
          ]}
          put={data => {
            console.log(data.text);
            api.save("texts", data.text);
          }}
          submit={translate("ADD TEXT")}
        />
      )}
    </>
  );
}

Texts.propTypes = {
  user: PropTypes.object.isRequired
};

// ========================================
