import React from "react";
import PropTypes from "prop-types";

import Konva from "konva";
import { Group, Circle, Text } from "react-konva";

import { useCyclicState } from "../../hooks";
import { eventPosition } from "../dnd";
import { radius, circleProps, textProps, wedgeProps } from "./ShapeProps";
import View from "./View";
import { Battery, levels } from "./Battery";

export default function Actor({
  angle,
  battery,
  color,
  interactive,
  label,
  updateActor,
  onDrop,
  onSelect,
  rotation,
  setActiveView,
  value, // silence warning
  zoom,
  ...props
}) {
  const [level, forwardLevel] = useCyclicState(
    levels,
    typeof battery === "boolean" ? undefined : battery
  );
  let lag = 0;
  const opacity = interactive ? 1.0 : 0.3;
  // /const actorColor = color;

  const dragProps = {
    draggable: interactive,
    onDragStart(e) {
      // visualize drag state
      e.target.setAttrs({
        shadowOffset: {
          x: 15,
          y: 15
        },
        scaleX: 1.1,
        scaleY: 1.1
      });
    },
    onDragMove(e) {
      updateActor(eventPosition(e, zoom), lag % 10 === 0);
      lag += 1;
    },
    onDragEnd(e) {
      // visualize drag state
      e.target.to({
        duration: 0.5,
        easing: Konva.Easings.ElasticEaseOut,
        scaleX: 1,
        scaleY: 1,
        shadowOffsetX: 5,
        shadowOffsetY: 5
      });
      onDrop(e);
    }
  };

  const viewProps = {
    rotation,
    angle,
    setActiveView,
    updateActor,
    interactive,
    innerRadius: radius - 10,
    opacity,
    onSelect
  };

  function forwardBattery() {
    updateActor({ battery: forwardLevel() || battery });
  }

  const batteryHandlers =
    battery !== false && interactive
      ? {
        //Leon/ENH: Potential: single click for battery operations
        onClick: forwardBattery,
        onTap: forwardBattery,
        onDblClick: forwardBattery,
        onDblTap: forwardBattery
      }
      : {};

  return (
    <Group
      {...props}
      {...dragProps}
      opacity={opacity}
      onMouseDown={() => onSelect(true)}
      onTouchStart={() => onSelect(true)}
      onMouseUp={() => onSelect(false)}
      onTouchEnd={() => onSelect(false)}
      {...batteryHandlers}
    >
      <View {...viewProps} />
      <Circle
        {...circleProps}
        stroke={props.ownerId ? "red" : circleProps.stroke}
        radius={radius - 10}
        fill={color}
      />
      {level && <Battery level={level} />}
      <Text {...textProps} text={label} />
    </Group>
  );
}

Actor.propTypes = {
  angle: PropTypes.number,
  battery: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  rotation: PropTypes.number
};

Actor.defaultProps = {
  angle: wedgeProps.angle,
  battery: false,
  //color: "yellow", // ignored and hard-coded above
  onSelect: () => { },
  rotation: 0
};
